<template>
  <div style="padding: 20px">
    <div style="text-align: right; padding-bottom: 20px">
      <el-button type="primary" @click="addVisible = true">添加批次</el-button>
    </div>
    <el-table :data="list" border stripe>
      <el-table-column prop="batch_number" label="批次编号" />
      <el-table-column prop="title" label="批次名称" />
      <el-table-column prop="reserve_purchase_at" label="预约购开启/结束时间(UTC+8)" width="210px">
        <template slot-scope="scope">
          <div
            v-if="scope.row.reserve_purchase_start || scope.row.reserve_purchase_end"
            style="text-align: center"
          >
            <div>{{ scope.row.reserve_purchase_start | dateFormater }}</div>
            <div>至</div>
            <div>{{ scope.row.reserve_purchase_end | dateFormater }}</div>
          </div>
          <div v-else style="text-align: center">-</div>
        </template>
      </el-table-column>
      <el-table-column width="200" prop="priority_purchase_at" label="优先购开启时间(UTC+8)">
        <template slot-scope="scope">
          {{ scope.row.priority_purchase_at | dateFormater }}
        </template>
      </el-table-column>
      <el-table-column prop="on_sell_at" label="上新时间(UTC+8)">
        <template slot-scope="scope">
          {{ scope.row.on_sell_at | dateFormater }}
        </template>
      </el-table-column>
      <el-table-column prop="nft_album_count" label="关联合集数量" />
      <el-table-column prop="nft_count" label="关联藏品数" />

      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button size="mini" type="info" @click="handleView(scope.row)">
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px; text-align: right">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="添加批次"
      :visible.sync="addVisible"
      width="480px"
      @close="handleHideAddDialog"
      center
      top="20px"
    >
      <div style="padding: 0 10px;">
        <el-form
          :model="addForm"
          ref="addForm"
          :rules="addFormRules"
          :inline="false"
          size="normal"
          label-position="top"
          class="priority-buy-edit-form"
        >
          <el-form-item label="批次名称" prop="title">
            <el-input style="width: 400px;" v-model="addForm.title" placeholder="请输入名称" />
          </el-form-item>
          <el-form-item label="预约购开启/结束时间" prop="reserve_purchase_range">
            <el-date-picker
              style="width: 400px;"
              v-model="addForm.reserve_purchase_range"
              type="datetimerange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="优先购开启时间(UTC+8)" prop="priority_purchase_at">
            <el-date-picker
              style="width: 400px;"
              v-model="addForm.priority_purchase_at"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="上新时间(UTC+8)" prop="on_sell_at">
            <el-date-picker
              style="width: 400px;"
              v-model="addForm.on_sell_at"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleClickAddBatch">确定</el-button>
            <el-button @click="handleHideAddDialog">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      top="20px"
      :title="isEdit ? '编辑批次' : '查看批次'"
      :visible.sync="visible"
      width="480px"
      @close="visible = false"
    >
      <div style="padding: 0 10px;">
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          :inline="false"
          size="normal"
          label-position="top"
          class="priority-buy-edit-form"
          :disabled="!isEdit"
        >
          <el-form-item label="批次名称" prop="title">
            <el-input style="width: 400px;" v-model="form.title" placeholder="请输入名称" />
          </el-form-item>
          <el-form-item label="预约购开启/结束时间" prop="reserve_purchase_range">
            <el-date-picker
              style="width: 400px;"
              v-model="form.reserve_purchase_range"
              type="datetimerange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled="isDisabled"
            />
          </el-form-item>
          <el-form-item label="优先购开启时间(UTC+8)" prop="priority_purchase_at">
            <el-date-picker
              style="width: 400px;"
              v-model="form.priority_purchase_at"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间"
              :disabled="isDisabled"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="上新时间(UTC+8)" prop="on_sell_at">
            <el-date-picker
              style="width: 400px;"
              v-model="form.on_sell_at"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间"
              :disabled="isDisabled"
            >
            </el-date-picker>
          </el-form-item>
          <div>
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <span style="margin-right: 8px">关联集合数</span>
                <span>{{ currentBatch.nft_album_count }}</span>
              </el-col>
              <el-col :span="12" :offset="0">
                <span style="margin-right: 8px">关联藏品数</span>
                <span>{{ currentBatch.nft_count }}</span>
              </el-col>
            </el-row>
            <div style="padding: 20px 0">
              <el-table :data="nfts" border stripe height="250">
                <el-table-column prop="name" label="关联集合名称"> </el-table-column>
                <el-table-column prop="count" label="上架数量"> </el-table-column>
              </el-table>
            </div>
          </div>
          <el-form-item v-if="isEdit">
            <el-button type="primary" @click="handleClickEditBatch">确定</el-button>
            <el-button @click="visible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getBatchList, addBatch, getBatchNFTList, editBatch } from "@/api/nft";

const format2Seconds = (time) => {
  if (!time) {
    return undefined;
  }
  return Math.floor(+time / 1000);
};

const getRange = (start, end) => {
  if (start === 0 && end === 0) {
    return [];
  }
  return [start * 1000, end * 1000];
};

export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 20,
      total: 0,
      visible: false,
      isEdit: false,
      addVisible: false,
      addForm: {},
      form: {},
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        on_sell_at: [{ required: true, message: "请输入选择日期", trigger: "blur" }],
      },
      currentBatch: {},
      addFormRules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        on_sell_at: [{ required: true, message: "请输入选择日期", trigger: "blur" }],
      },
      nfts: [],
    };
  },
  filters: {
    dateFormater: function(value) {
      if (!value) {
        return "-";
      }
      return dayjs(+value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  computed: {
    isDisabled() {
      const { reserve_purchase_start, priority_purchase_at, on_sell_at } = this.currentBatch;
      const now = Date.now();
      if (reserve_purchase_start > 0 && reserve_purchase_start * 1000 <= now) {
        return true;
      }
      if (priority_purchase_at > 0 && priority_purchase_at * 1000 <= now) {
        return true;
      }
      if (on_sell_at > 0 && on_sell_at * 1000 <= now) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const { data } = await getBatchList({
          page: this.page,
          page_size: this.pageSize,
        });
        this.list = data.data.items || [];
        this.total = data.data.total || 0;
      } catch (error) {}
    },
    async fetchNFTList(batchNumber) {
      try {
        const { data } = await getBatchNFTList({
          batch_number: batchNumber,
        });
        this.nfts = data.data.nfts || [];
      } catch (error) {
        this.nfts = [];
      }
    },
    handleEdit(item) {
      this.form = {
        title: item.title,
        on_sell_at: item.on_sell_at * 1000,
        priority_purchase_at: item.priority_purchase_at * 1000,
        reserve_purchase_range: getRange(item.reserve_purchase_start, item.reserve_purchase_end),
      };
      this.currentBatch = { ...item };
      this.fetchNFTList(item.batch_number);
      this.isEdit = true;
      this.visible = true;
    },
    handleView(item) {
      this.form = {
        title: item.title,
        on_sell_at: item.on_sell_at * 1000,
        priority_purchase_at: item.priority_purchase_at * 1000,
        reserve_purchase_range: getRange(item.reserve_purchase_start, item.reserve_purchase_end),
      };
      this.currentBatch = { ...item };
      this.fetchNFTList(item.batch_number);
      this.isEdit = false;
      this.visible = true;
    },
    handleClickEditBatch() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.handleEditBatch();
        } else {
          return false;
        }
      });
    },
    async handleEditBatch() {
      const reserveRange = this.form.reserve_purchase_range;
      const priorityAt = this.form.priority_purchase_at;
      const sellAt = this.form.on_sell_at;

      if (sellAt <= priorityAt) {
        this.$message.error("上新时间不能小于优先购开启时间");
        return false;
      }

      if (reserveRange !== undefined) {
        const [start, end] = reserveRange;
        if (priorityAt !== undefined && priorityAt !== 0) {
          if (end > priorityAt) {
            this.$message.error("预约购时间须早于优先购");
            return false;
          }
        }

        if (end > sellAt) {
          this.$message.error("预约购时间须早于上新时间");
          return false;
        }
      }

      try {
        let params = {
          title: this.form.title,
          on_sell_at: format2Seconds(sellAt),
          batch_number: this.currentBatch.batch_number,
        };
        if (reserveRange !== undefined) {
          const [start, end] = reserveRange;
          params.reserve_purchase_start = format2Seconds(start);
          params.reserve_purchase_end = format2Seconds(end);
        }
        if (priorityAt !== undefined) {
          params.priority_purchase_at = format2Seconds(priorityAt);
        }
        await editBatch(params);
        this.$message.success("编辑成功");
        this.fetchData();
        this.handleHideEditDialog();
      } catch (error) {
        console.log(error);
      }
    },
    handleClickAddBatch() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.handleAddBatch();
        } else {
          console.log(valid, this.form);
          return false;
        }
      });
    },
    async handleAddBatch() {
      const reserveRange = this.addForm.reserve_purchase_range;
      const priorityAt = this.addForm.priority_purchase_at;
      const sellAt = this.addForm.on_sell_at;

      if (sellAt <= priorityAt) {
        this.$message.error("上新时间不能小于优先购开启时间");
        return false;
      }

      if (reserveRange !== undefined) {
        const [start, end] = reserveRange;
        if (priorityAt !== undefined && priorityAt !== 0) {
          if (end > priorityAt) {
            this.$message.error("预约购时间须早于优先购");
            return false;
          }
        }

        if (end > sellAt) {
          this.$message.error("预约购时间须早于上新时间");
          return false;
        }
      }

      try {
        let params = {
          title: this.addForm.title,
          on_sell_at: format2Seconds(sellAt),
        };
        if (reserveRange !== undefined) {
          const [start, end] = reserveRange;
          params.reserve_purchase_start = format2Seconds(start);
          params.reserve_purchase_end = format2Seconds(end);
        }
        if (priorityAt !== undefined) {
          params.priority_purchase_at = format2Seconds(priorityAt);
        }

        await addBatch(params);
        this.$message.success("添加成功");
        this.fetchData();
        this.handleHideAddDialog();
      } catch (error) {
        console.log(error);
      }
    },
    handleHideAddDialog() {
      this.addVisible = false;
      this.addForm = {};
    },
    handleHideEditDialog() {
      this.visible = false;
      this.form = {};
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped></style>
